<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />

      <ContentAndComment
        v-show="currentComponent === 'ContentAndComment'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import Vue from 'vue';
import ServiceAuthorization from '@/views/Speditors/Suus/Forms/ServiceAuthorization.vue';
import ContentAndComment from '@/views/Speditors/Suus/Forms/ContentAndComment.vue';
import AdditionalServices from '@/views/Speditors/Suus/Forms/AdditionalServices.vue';
import { Suus } from '@/constants/Speditors/Suus/configuration/Suus.js';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import { v4 as uuidv4 } from 'uuid';
import { TEST_SUUS_URL } from '@/constants/Speditors/testApi.js';
import { PROD_SUUS_URL } from '@/constants/Speditors/prodApi.js';

export default Vue.extend({
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    ContentAndComment,
    AdditionalServices,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'Suus',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.sideNavigation.authorizationData' },
      { code: 'AdditionalServices', name: 'courierSet.sideNavigation.additionalServices' },
      { code: 'ContentAndComment', name: 'courierSet.sideNavigation.contentAndComments' },
    ],
  }),
  computed: {
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `https://${PROD_SUUS_URL}/websrv/labs.php`;
      }
      return `https://${TEST_SUUS_URL}/websrv/labs.php`;
    },
  },
  methods: {
    setNewConfiguration() {
      Suus.tenantId = this.oidcUser.tenantId;
      Suus.id = uuidv4();
      Suus.urlAddress = this.getUrlAddress;
      this.setConfigSet({ response: Suus });
    },
  },
});
</script>
