export const TEST_DPD_URL = 'dpdservicesdemo.dpd.com.pl';
export const TEST_POCZTA_POLSKA_URL = 'en-testwebapi.poczta-polska.pl';
export const TEST_ORLEN_PACZKA_URL = 'api-test.orlenpaczka.pl';
export const TEST_INPOST_URL = 'sandbox-api-shipx-pl.easypack24.net';
export const TEST_GLS_URL = 'ade-test.gls-poland.com';
export const TEST_DHL_URL = 'sandbox.dhl24.com.pl';
export const TEST_AMBRO_EXPRESS_URL = 'dev.api.ambro.opennet.pl';
export const TEST_UPS_URL = 'api.ambro.opennet.pl';
export const TEST_SUUS_URL = 'wbtest.suus.com';
export const TEST_FEDEX_URL = 'apis-sandbox.fedex.com';
export const TEST_FEDEX_DOMESTIC_URL = 'test.poland.fedex.com/fdsWs/IklServicePort?wsdl';
export const TEST_DBSHENKER_URL = 'testapi2.schenker.pl';
export const TEST_DBSHENKER_INTERNATIONAL_URL = 'eschenker-fat.dbschenker.com';
