export const PROD_DPD_URL = 'dpdservices.dpd.com.pl';
export const PROD_POCZTA_POLSKA_URL = 'e-nadawca.poczta-polska.pl';
export const PROD_ORLEN_PACZKA_URL = 'api.orlenpaczka.pl';
export const PROD_INPOST_URL = 'api-shipx-pl.easypack24.net';
export const PROD_GLS_URL = 'adeplus.gls-poland.com';
export const PROD_DHL_URL = 'dhl24.com.pl';
export const PROD_AMBRO_EXPRESS_URL = 'api.ambro.opennet.pl';
export const PROD_UPS_URL = 'api.ambro.opennet.pl';
export const PROD_SUUS_URL = 'wb.suus.com';
export const PROD_FEDEX_URL = 'apis-sandbox.fedex.com';
export const PROD_FEDEX_DOMESTIC_URL = 'poland.fedex.com/fdsWs/IklServicePort?WSDL';
export const PROD_DBSHENKER_URL = 'api2.schenker.pl';
export const PROD_DBSHENKER_INTERNATIONAL_URL = 'eschenker-fat.dbschenker.com';
